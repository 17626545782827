import {AppProps} from 'next/app';
import {useRouter} from 'next/compat/router';
import React, {useEffect, useState, useCallback, useRef} from 'react';
import Head from 'next/head';
import flowRight from 'lodash/flowRight';
import Loader from '../components/system/Loader';
import config from '../config';
import {I18nContextProvider} from '../i18n';
import {withCookieContext} from '../contexts/cookies';
import {withRequestContext} from '../contexts/request';
import {withDataLayerContext} from '../contexts/tagManager';
import {withAuthContext} from '../contexts/auth';
import {withAnalyticsContext} from '../contexts/analytics';
import CapcarIcon from '../public/static/svg/capcar.svg';
import {withAccountMenu} from '../components/rich/AccountMenu/context';
import {fetchDataCustomerReview} from '../utils/customerReview';
import Toast from '../components/system/Toast';
import type {CustomerReviewInfo} from '../utils/customerReview';
import '../styles/index.css';

const {CDN_HOST} = config;
const MAX_WAIT_TIME_ROUTE_CHANGE_MS = 2000;

type AppOwnProps = {
	customerReview?: CustomerReviewInfo;
};

const MyApp = ({
	Component,
	pageProps,
	customerReview,
}: AppProps & AppOwnProps) => {
	const router = useRouter();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);

	const routerChangeStart = useCallback(
		(e: string) => {
			if (router.asPath === e) return;

			if (timeoutRef.current) clearTimeout(timeoutRef.current);

			timeoutRef.current = setTimeout(() => {
				setIsLoading(true);
			}, MAX_WAIT_TIME_ROUTE_CHANGE_MS);
		},
		[router.asPath],
	);

	const routerChangeDone = useCallback(() => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		setIsLoading(false);
	}, []);

	useEffect(() => {
		router.events.on('routeChangeStart', routerChangeStart);
		router.events.on('routeChangeComplete', routerChangeDone);
		router.events.on('routeChangeError', routerChangeDone);

		return () => {
			router.events.off('routeChangeStart', routerChangeStart);
			router.events.off('routeChangeComplete', routerChangeDone);
			router.events.off('routeChangeError', routerChangeDone);

			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [routerChangeStart, routerChangeDone, router.events]);

	if (isLoading) {
		return (
			<div className="fixed right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
				<CapcarIcon className="mx-auto fill-blue-275" />
				<Loader className="mx-auto w-40 h-40" />
			</div>
		);
	}
	return (
		<I18nContextProvider>
			<Head>
				{!!customerReview && (
					<script
						type="application/ld+json"
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{
							__html: `
										{
										  "@context": "http://schema.org",
										  "@type": "AutoDealer",
										  "name": "CapCar.fr",
										  "url": "www.capCar.fr",
										  "logo": "${CDN_HOST}/static/theme-capcar/logo-capcar-blue.svg",
										  "description": "CapCar est une plateforme d'intermédiation spécialisée dans la vente de voitures d'occasion entre particuliers. Nous offrons un service clé en main incluant estimation gratuite, inspection des véhicules, accompagnement administratif et sécurisation des paiements.",
										  "address": {
											"@type": "PostalAddress",
											"addressLocality": "Paris",
											"postalCode": "75002",
											"streetAddress": "3 rue de Gramont"
										  },
										  "makesOffer": [
										    {
										      "@type": "Offer",
										      "name": "Intermédiation pour vente de voitures d'occasion",
										      "description": "Accompagnement complet pour vendre votre voiture en toute sécurité."
										    },
										    {
										      "@type": "Offer",
										      "name": "Estimation gratuite du prix de vente",
										      "description": "Recevez une estimation précise et gratuite basée sur le marché actuel."
										    },
										    {
										      "@type": "Offer",
										      "name": "Rachat de véhicules",
										      "description": "Nous rachetons votre véhicule rapidement et au meilleur prix."
										    },
											{
											  "@type": "Offer",
											  "name": "Contrôle du véhicule",
											  "description": "Nos agents effectuent un contrôle rigoureux de votre véhicule, analysant son état mécanique, esthétique et administratif. Cette inspection détaillée assure une évaluation précise et renforce la confiance des acheteurs pour une transaction en toute sérénité."
											},
											{
											  "@type": "Offer",
											  "name": "Reportage photo professionnel",
											  "description": "Nos agents capturent des images haute définition de votre véhicule sous ses meilleurs angles. Un shooting optimisé pour maximiser son attractivité et attirer plus d'acheteurs potentiels."
											},
										    {
										      "@type": "Offer",
										      "name": "Sécurisation de la transaction et du paiement",
										      "description": "Nous gérons la transaction pour éviter les fraudes et sécuriser le paiement."
										    },
										    {
										      "@type": "Offer",
										      "name": "Accompagnement administratif",
										      "description": "Nous nous occupons de toutes les démarches pour la vente de votre voiture."
										    }
										  ],
										  "aggregateRating": {
											"@type": "AggregateRating",
											"bestRating": 5,
											"ratingValue": "${customerReview?.note}",
											"ratingCount": "${customerReview?.nbPreview}",
											"reviewCount": "${customerReview?.nbPreview}"
										  }
										}
									`,
						}}
					/>
				)}

				<link
					rel="preconnect"
					href="https://res.cloudinary.com"
					crossOrigin="anonymous"
				/>

				{CDN_HOST && (
					<link
						rel="preconnect"
						href={CDN_HOST}
						crossOrigin="use-credentials"
					/>
				)}

				<meta
					name="google-site-verification"
					content="UhCiCb_O6b6aY47HAoS-ULKsR-HqD6EsgDOkUfirqLg"
				/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, user-scalable=1, minimum-scale=1"
				/>
				<meta name="theme-color" content="#145DF0" />
				<link
					rel="icon"
					href={`${CDN_HOST}/static/favicon.png`}
					sizes="32x32"
				/>
				<link
					rel="icon"
					href={`${CDN_HOST}/static/favicon.png`}
					sizes="192x192"
				/>
				<link
					rel="apple-touch-icon"
					href={`${CDN_HOST}/static/favicon.png`}
				/>
			</Head>
			<Component {...pageProps} />
			<Toast />
		</I18nContextProvider>
	);
};

MyApp.getInitialProps = async ({Component, ctx}) => {
	const pageProps = Component.getInitialProps
		? await Component.getInitialProps(ctx)
		: {};

	try {
		const customerReview = await fetchDataCustomerReview();
		return {
			customerReview,
			pageProps,
		};
	} catch {
		return {
			pageProps,
		};
	}
};

export default flowRight(
	withRequestContext,
	withAnalyticsContext,
	withDataLayerContext,
	withAuthContext,
	withCookieContext,
	withAccountMenu,
)(MyApp);
