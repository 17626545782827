import React, {useReducer} from 'react';
import {fr} from 'date-fns/locale';
import format from 'date-fns/format';
import parser from 'html-react-parser';
import ALL_FR from './all-fr';

const translations = {
	fr: Object.assign({}, ...ALL_FR),
};
const dateTranslations = {
	fr,
};

const fillTemplate = (templateString, templateVariables) =>
	templateString.replace(/\${(.*?)}/g, (_, g) => templateVariables[g]);

export type TranslateParser = string | JSX.Element | JSX.Element[];

export const getTranslate =
	(langCode: keyof typeof translations) =>
	(key: string, args?: Record<string, any>): string => {
		const template = translations[langCode][key];

		if (!template) {
			return key;
		}

		if (args) {
			return fillTemplate(template, args);
		}

		return template;
	};

export const getTranslateParser =
	(langCode: keyof typeof translations) =>
	(key: string, args?: Record<string, any>): TranslateParser =>
		parser(getTranslate(langCode)(key, args));

const getFormatDate =
	(locale: string) =>
	(date: Date, formatStr = 'PP') =>
		format(date, formatStr, {
			locale: dateTranslations[locale],
		});

const initialState = {
	langCode: 'fr',
	translate: getTranslate('fr'),
	translateParser: getTranslateParser('fr'),
	formatDate: getFormatDate('fr'),
};
export const I18nContext =
	React.createContext<typeof initialState>(initialState);

if (process.env.NODE_ENV === 'development') {
	I18nContext.displayName = 'I18nContext';
}

export const I18nContextProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const reducer = (state, action) => {
		if (action.type === 'setLanguage') {
			return {
				langCode: action.payload,
				translate: getTranslate(action.payload),
				translateParser: getTranslateParser(action.payload),
				formatDate: getFormatDate(action.payload),
			};
		}

		return initialState;
	};

	const [state] = useReducer(reducer, initialState);
	return (
		<I18nContext.Provider value={state}>{children}</I18nContext.Provider>
	);
};
