import Router from 'next/router';
import cookie from 'js-cookie';
import config from '../../config';
import {COOKIE_OPTIONS} from './consts';
import type {LoginParam} from './types';

const {ENV_NAME} = config;

function sanitizeCookieName(cookieName: string): string {
	const envName = ENV_NAME ?? 'development';

	if (envName === 'production') {
		return cookieName;
	}

	return `${envName}_${cookieName}`;
}

export function getAuthCookies(cookies: any): any {
	const envName = ENV_NAME ?? 'development';

	if (envName === 'production') {
		return cookies;
	}

	return {
		customerId: cookies[`${envName}_customerId`],
		token: cookies[`${envName}_token`],
		firstName: cookies[`${envName}_firstName`],
		lastName: cookies[`${envName}_lastName`],
		email: cookies[`${envName}_email`],
		phone: cookies[`${envName}_phone`],
	};
}
export const login = ({
	id,
	token,
	firstName,
	lastName,
	email,
	phone,
	apolloClient,
	resetStore = true,
}: LoginParam) => {
	if (resetStore) apolloClient.resetStore();
	cookie.set(sanitizeCookieName('customerId'), id, COOKIE_OPTIONS);
	cookie.set(sanitizeCookieName('token'), token, COOKIE_OPTIONS);
	cookie.set(
		sanitizeCookieName('firstName'),
		firstName || '',
		COOKIE_OPTIONS,
	);
	cookie.set(sanitizeCookieName('lastName'), lastName || '', COOKIE_OPTIONS);
	cookie.set(sanitizeCookieName('email'), email || '', COOKIE_OPTIONS);
	cookie.set(sanitizeCookieName('phone'), phone || '', COOKIE_OPTIONS);
};
export const changeUserName = (firstName: string, lastName: string) => {
	cookie.set(
		sanitizeCookieName('firstName'),
		firstName || '',
		COOKIE_OPTIONS,
	);
	cookie.set(sanitizeCookieName('lastName'), lastName || '', COOKIE_OPTIONS);
};
export const logout = (apolloClient?: any, redirection?: string) => {
	if (typeof window !== 'undefined') {
		if (apolloClient) {
			apolloClient.clearStore();
		}
		cookie.remove(sanitizeCookieName('customerId'), COOKIE_OPTIONS);
		cookie.remove(sanitizeCookieName('token'), COOKIE_OPTIONS);
		cookie.remove(sanitizeCookieName('firstName'), COOKIE_OPTIONS);
		cookie.remove(sanitizeCookieName('lastName'), COOKIE_OPTIONS);
		cookie.remove(sanitizeCookieName('email'), COOKIE_OPTIONS);
		cookie.remove(sanitizeCookieName('phone'), COOKIE_OPTIONS);
		// @todo : fix logout in prod (provisual)
		cookie.remove(sanitizeCookieName('customerId'), {
			...COOKIE_OPTIONS,
			domain: 'www.capcar.fr',
		});
		cookie.remove(sanitizeCookieName('token'), {
			...COOKIE_OPTIONS,
			domain: 'www.capcar.fr',
		});
		cookie.remove(sanitizeCookieName('firstName'), {
			...COOKIE_OPTIONS,
			domain: 'www.capcar.fr',
		});
		cookie.remove(sanitizeCookieName('lastName'), {
			...COOKIE_OPTIONS,
			domain: 'www.capcar.fr',
		});
		cookie.remove(sanitizeCookieName('email'), {
			...COOKIE_OPTIONS,
			domain: 'www.capcar.fr',
		});
		cookie.remove(sanitizeCookieName('phone'), {
			...COOKIE_OPTIONS,
			domain: 'www.capcar.fr',
		});
		Router.push(redirection || '/');
	}
};
