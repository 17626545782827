import React, {createContext, useMemo} from 'react';
import type {PageType, AppType} from '../types/PageType';
import {isMobile} from '../utils/userAgent';

type RequestContextType = {
	isMobile: boolean;
};
export const RequestContext = createContext<RequestContextType>({
	isMobile: isMobile(null),
});

if (process.env.NODE_ENV === 'development') {
	RequestContext.displayName = 'RequestContext';
}

export const withRequestContext = (Component: PageType | AppType) => {
	const Wrapper = ({mobile, ...props}: any) => {
		const providerValue = useMemo(
			() => ({
				isMobile: mobile,
			}),
			[mobile],
		);

		return (
			<RequestContext.Provider value={providerValue}>
				<Component {...props} />
			</RequestContext.Provider>
		);
	};

	Wrapper.getInitialProps = async ctx => {
		const componentProps = Component.getInitialProps
			? await Component.getInitialProps(ctx)
			: {};
		return {
			...componentProps,
			mobile: isMobile(ctx.ctx ? ctx.ctx.req : ctx.req),
		};
	};

	return Wrapper;
};
