import React, {createContext, useMemo, useEffect, useRef} from 'react';
import config from '../config';
import type {AppType} from '../types/PageType';

const {GTM_ID, GTM_AUTH, GTM_PREVIEW} = config;

export type TagManagerContextType = {
	dataLayer: (data: Record<string, any>, name: string) => void;
};

export const DataLayerContext = createContext<TagManagerContextType>({
	dataLayer: () => null,
});

if (process.env.NODE_ENV === 'development') {
	DataLayerContext.displayName = 'TagManagerContext';
}

export const withDataLayerContext = (Component: AppType) => {
	const Wrapper = (props: Record<string, any>) => {
		const isInitialized = useRef(false);

		useEffect(() => {
			if (
				GTM_ID &&
				typeof window !== 'undefined' &&
				!isInitialized.current
			) {
				const scriptId = 'gtm-script';
				const existingScript = document.getElementById(scriptId);

				if (!existingScript) {
					const LoadGTM = () => {
						const script = document.createElement('script');
						script.id = scriptId;
						script.src = `https://www.googletagmanager.com/gtm.js?id=${GTM_ID}&gtm_auth=${GTM_AUTH}&gtm_preview=${GTM_PREVIEW}&gtm_cookies_win=x`;
						script.async = true;

						document.head.appendChild(script);

						window.gmo = window.gmo || {};
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({
							'gtm.start': new Date().getTime(),
							event: 'gtm.js',
						});

						isInitialized.current = true;
					};

					if ('requestIdleCallback' in window) {
						window.requestIdleCallback(LoadGTM);
						return undefined;
					}

					const timeoutId = setTimeout(LoadGTM, 3000);
					return () => clearTimeout(timeoutId);
				}
			}

			return undefined;
		}, []);

		const dataLayer =
			GTM_ID && typeof window !== 'undefined'
				? (data, name) => {
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({...data, event: name});
					}
				: () => undefined;

		const providerValue = useMemo(
			() => ({
				dataLayer,
			}),
			[dataLayer],
		);

		return (
			<DataLayerContext.Provider value={providerValue}>
				<Component {...props} />
			</DataLayerContext.Provider>
		);
	};

	Wrapper.getInitialProps = async ctx =>
		Component.getInitialProps ? Component.getInitialProps(ctx) : {};

	return Wrapper;
};
