import React from 'react';
import cx from 'classnames';
import LoaderIcon from '../../../public/static/svg/spinner.svg';

type Props = {className?: string};

const Loader = ({className}: Props) => (
	<LoaderIcon
		className={cx('text-blue-275 fill-current stroke-current', className)}
	/>
);

export default Loader;
