import type {PropsWithChildren} from 'react';
import React, {createContext, useMemo, useState} from 'react';
import Cookie from 'js-cookie';
import nextCookie from 'next-cookies';
import type {AppType} from '../types/PageType';

type CookieOption = {
	name: string;
	value?: string | boolean | number;
	expires?: number;
	extra?: Record<string, any>;
};
type Cookies = Record<string, string | boolean | number>;
export type CookieContextType = {
	cookies: Cookies;
	addCookie: (arg0: CookieOption) => void;
};
export const FUNNEL_VERSION_COOKIE = 'funnelVersion';
const DEFAULT_COOKIE = {
	value: true,
	expires: 365,
};
export const CookieContext = createContext<CookieContextType>({
	cookies: {},
	addCookie: () => null,
});

if (process.env.NODE_ENV === 'development') {
	CookieContext.displayName = 'CookieContext';
}

const CookieContextProvider = ({
	children,
	initialCookies,
}: PropsWithChildren<{
	initialCookies: Cookies;
}>) => {
	const [cookies, setState] = useState(initialCookies);

	const addCookie = (newCookie: CookieOption) => {
		const value = String(newCookie.value || DEFAULT_COOKIE.value);
		Cookie.set(newCookie.name, value, {
			...newCookie.extra,
			expires: newCookie.expires || DEFAULT_COOKIE.expires,
		});
		setState({
			...cookies,
			[newCookie.name]: value,
		});
	};

	const providerValue = useMemo(
		() => ({
			cookies,
			addCookie,
		}),
		[cookies, addCookie],
	);

	return (
		<CookieContext.Provider value={providerValue}>
			{children}
		</CookieContext.Provider>
	);
};

export const withCookieContext = (Component: AppType) => {
	const Wrapper = ({cookies, ...rest}: any) => (
		<CookieContextProvider initialCookies={cookies || {}}>
			<Component {...rest} cookies={cookies} />
		</CookieContextProvider>
	);

	Wrapper.getInitialProps = async props => {
		const pageProps = Component.getInitialProps
			? await Component.getInitialProps(props)
			: {};
		const cookies = nextCookie(props.ctx) || {};
		return {...pageProps, cookies};
	};

	return Wrapper;
};
