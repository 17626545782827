import React from 'react';
import {ToastContainer} from 'react-toastify';

export const Toast: React.FC = () => (
	<ToastContainer
		autoClose={TOAST_AUTO_CLOSE_TIME}
		limit={TOAST_MAX_SHOW}
		hideProgressBar
		position={TOAST_POSITION}
		theme={TOAST_THEME}
	/>
);

const TOAST_AUTO_CLOSE_TIME = 5000;
const TOAST_MAX_SHOW = 3;
const TOAST_POSITION = 'top-center';
const TOAST_THEME = 'colored';

export default Toast;
