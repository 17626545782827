import nextCookie from 'next-cookies';
import React, {
	useEffect,
	useContext,
	createContext,
	useMemo,
	useRef,
} from 'react';
import ReactGA from 'react-ga4';
import config from '../../config';
import type {AppType} from '../../types/PageType';
import type {ContextType, Tracker} from './types';
import {useTracker, appendParamsToUrl, getUtm} from './utils';

export const AnalyticsContext = createContext<ContextType>({
	tracker: {},
	ga: () => null,
	setTracker: () => null,
	withTracking: () => null,
});

if (process.env.NODE_ENV === 'development') {
	AnalyticsContext.displayName = 'AnalyticsContext';
}

const {GA_TRACKING_ID, GA_DEBUG} = config;

export const AnalyticsContextProvider = ({
	children,
	initialTracker,
}: {
	children: React.ReactNode;
	initialTracker: Tracker;
}) => {
	const [tracker, setTracker] = useTracker(initialTracker);
	const withTracking = appendParamsToUrl({
		utm_source: tracker.source,
		utm_campaign: tracker.campaign,
		utm_term: tracker.term,
		utm_medium: tracker.medium,
	});

	const providerValue = useMemo(
		() => ({
			tracker,
			setTracker,
			withTracking,
			ga: ReactGA.ga,
		}),
		[tracker, setTracker, withTracking, ReactGA.ga],
	);

	return (
		<AnalyticsContext.Provider value={providerValue}>
			{children}
		</AnalyticsContext.Provider>
	);
};

const AnalyticsWrapper = ({router, Component}) => {
	const isInitialized = useRef(false);
	const {setTracker} = useContext(AnalyticsContext);
	useEffect(() => {
		if (!isInitialized.current && GA_TRACKING_ID) {
			const loadAnalytics = () => {
				ReactGA.initialize(GA_TRACKING_ID, {
					testMode: GA_DEBUG === 'true',
				});

				ReactGA.set({
					anonymizeIp: true,
				});

				isInitialized.current = true;
			};

			// eslint-disable-next-line no-underscore-dangle
			if (typeof window._axcb === 'undefined') {
				// eslint-disable-next-line no-underscore-dangle
				window._axcb = [];
			}

			// eslint-disable-next-line no-underscore-dangle
			window._axcb.push(function (axeptio) {
				axeptio.on('cookies:complete', function (choices) {
					if (choices.google_analytics) {
						loadAnalytics();
					}
				});
			});
		}

		return undefined;
	}, [GA_TRACKING_ID, GA_DEBUG]);

	useEffect(() => {
		const {asPath, query} = router;

		if (isInitialized.current) {
			ReactGA.ga('set', 'page', asPath);
			ReactGA.ga('send', 'pageview');
		}

		if (query.utm_source || query.utm_medium) {
			setTracker({
				source: getUtm(query.utm_source),
				campaign: getUtm(query.utm_campaign),
				term: getUtm(query.utm_term),
				medium: getUtm(query.utm_medium),
			});
		}
	}, [router.asPath, isInitialized.current]);
	return Component;
};

export const withAnalyticsContext = (Component: AppType) => {
	const Wrapper = ({router, tracker, ...rest}: any) => (
		<AnalyticsContextProvider initialTracker={tracker}>
			<AnalyticsWrapper
				router={router}
				Component={<Component router={router} {...rest} />}
			/>
		</AnalyticsContextProvider>
	);

	Wrapper.getInitialProps = async props => {
		let pageProps;
		const {tracker} = nextCookie(props.ctx);
		props.ctx.tracker = tracker;

		try {
			pageProps = Component.getInitialProps
				? await Component.getInitialProps(props)
				: {};
		} catch {
			pageProps = {};
		}

		return {...pageProps, tracker};
	};

	return Wrapper;
};
