import {gql} from '@apollo/client';

export const productFragment = gql`
	fragment ProductFragment on Product {
		id
		price
		internalStatus
		thumb
		detail {
			id
			car {
				brand
				version
			}
		}
	}
`;
