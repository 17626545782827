// !! This file is used in the webpack process, do not use ES6 module inside this file.
const GLOBAL_FR = require('./dictionary/global.fr.json');
const PRODUCT_FR = require('./dictionary/product.fr.json');
const PRIME_CONVERTION_FR = require('./dictionary/prime-convertion.fr.json');
const ALERT_FR = require('./dictionary/alert.fr.json');
const BARGAINING_FR = require('./dictionary/bargaining.fr.json');
const WARRANTY_FR = require('./dictionary/warranty.fr.json');
const BOOKING_FR = require('./dictionary/booking.fr.json');
const DOCUMENTS_FR = require('./dictionary/documents.fr.json');
const INVOICES_FR = require('./dictionary/invoices.fr.json');
const CERTIFICATES_FR = require('./dictionary/certificates.fr.json');
const PAIEMENT_FR = require('./dictionary/paiement.fr.json');
const PARTNER_FR = require('./dictionary/partners.fr.json');
const VALIDATION_FR = require('./dictionary/validation.fr.json');
const CONTACT_FR = require('./dictionary/contact.fr.json');
const GLOSSARY_FR = require('./dictionary/glossary.fr.json');
const HOW_DOES_IT_WORK_FR = require('./dictionary/how-does-it-work.json');
const ESTIMATE_FR = require('./dictionary/estimate.fr.json');
const CAPCAR_BUY_BACK_FR = require('./dictionary/capcar-buyback.fr.json');
const WHO_ARE_WE_FR = require('./dictionary/who-are-we.fr.json');
const AUTH_FR = require('./dictionary/auth.fr.json');
const ALLIANZ_FR = require('./dictionary/allianz.fr.json');
const FIND_A_CAR = require('./dictionary/find-a-car.fr.json');
const SELL_MY_CAR = require('./dictionary/sell-my-car.fr.json');
const APPOINTMENT_AVAILABILITIES = require('./dictionary/appointment-availabilities.fr.json');
const FORM_FR = require('./dictionary/form.fr.json');
const MONTHS = require('./dictionary/months.fr.json');
const ICONS = require('./dictionary/icon.json');
const STATS_LISTING = require('./dictionary/stats-listing.fr.json');
const RESERVATION_OFFER_DESCRIPTION = require('./dictionary/reservation-offer-description.fr.json');
const BLOG_FR = require('./dictionary/blog.fr.json');
const FAVORITE = require('./dictionary/favorite.fr.json');
const SHARE = require('./dictionary/share.fr.json');
const AGENT = require('./dictionary/agent.fr.json');
const AGENCY = require('./dictionary/agency.fr.json');
const RATING = require('./dictionary/rating.fr.json');
const BREAKING = require('./dictionary/breaking.fr.json');
const ACCESS_ERROR_FR = require('./dictionary/access-error.fr.json');
const BECOME_AGENT_FR = require('./dictionary/become-agent.fr.json');
const VIDEOS_FR = require('./dictionary/videos.fr.json');

module.exports = [
	GLOBAL_FR,
	VALIDATION_FR,
	PRODUCT_FR,
	PRIME_CONVERTION_FR,
	ALERT_FR,
	WARRANTY_FR,
	BOOKING_FR,
	BARGAINING_FR,
	DOCUMENTS_FR,
	INVOICES_FR,
	CERTIFICATES_FR,
	PAIEMENT_FR,
	PARTNER_FR,
	CONTACT_FR,
	GLOSSARY_FR,
	HOW_DOES_IT_WORK_FR,
	ESTIMATE_FR,
	WHO_ARE_WE_FR,
	AUTH_FR,
	ALLIANZ_FR,
	FIND_A_CAR,
	SELL_MY_CAR,
	APPOINTMENT_AVAILABILITIES,
	FORM_FR,
	MONTHS,
	ICONS,
	STATS_LISTING,
	RESERVATION_OFFER_DESCRIPTION,
	CAPCAR_BUY_BACK_FR,
	BLOG_FR,
	FAVORITE,
	SHARE,
	AGENT,
	AGENCY,
	RATING,
	BREAKING,
	ACCESS_ERROR_FR,
	BECOME_AGENT_FR,
	VIDEOS_FR,
];
