import {useState} from 'react';
import cookie from 'js-cookie';
import type {Tracker} from './types';

export const useTracker = (initialTracker: Tracker = {}) => {
	const [tracker, setState] = useState(initialTracker);

	const setTracker = (newTracker: Tracker) => {
		cookie.set('tracker', JSON.stringify(newTracker));
		setState(newTracker);
	};

	return [tracker, setTracker] as const;
};

export const appendParamsToUrl =
	(params: Record<string, any>) => (url: string) => {
		if (!url.startsWith('http')) {
			return url;
		}

		const urlObject = new URL(url);
		Object.keys(params).forEach(key => {
			const value = params[key];

			if (value) {
				urlObject.searchParams.append(key, params[key]);
			}
		});
		return urlObject.toString();
	};

export const getUtm = (utm: string[] | string): string =>
	Array.isArray(utm) ? utm[0] : utm;
