type Request = {
	headers: {
		'user-agent': string;
	};
};

export const extractUserAgent = (req?: Request | null | undefined): string => {
	if (req && req.headers) {
		return req.headers['user-agent'];
	}

	if (typeof window !== 'undefined' && window.navigator?.userAgent) {
		return window.navigator.userAgent;
	}

	return '';
};

export const isMobile = (req?: Request | null | undefined): boolean => {
	const userAgent = extractUserAgent(req);

	if (!userAgent) {
		return false;
	}

	return Boolean(
		userAgent.match(
			/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
		),
	);
};

export const isMobileOrTabletNavigator = () =>
	/(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
