import {AVIS_VERIFIES_CL_LINK} from '../constants';
import {fetchApi} from './fetchApi';

const AVIS_VERIFIE_INFO_SITE_URL = `${AVIS_VERIFIES_CL_LINK}/fr/cache/c/7/5/c753bc29-ad68-41e4-61b0-1e28f5fc8865/AWS/c753bc29-ad68-41e4-61b0-1e28f5fc8865_infosite.txt`;
export type CustomerReviewInfo = {
	nbPreview: number;
	note: number;
};
export const fetchDataCustomerReview = async (): Promise<CustomerReviewInfo> =>
	fetchApi(AVIS_VERIFIE_INFO_SITE_URL, {}, 'text')
		.then(data => {
			const info = data.split(';');
			return {
				nbPreview: Number(info[0]),
				note: Number(Number(info[1]).toFixed(1)),
			};
		})
		.catch(() => {
			return {
				nbPreview: 207,
				note: 4.8,
			};
		});
