import fetch from 'isomorphic-unfetch';
import config from '../config';

const {PUBLIC_API_URI} = config;

type FetchOption = {
	method?: RequestInit['method'];
	headers?: RequestInit['headers'];
	credentials?: RequestInit['credentials'];
	body?: Record<string, unknown>;
	timeout?: number;
};

export const fetchApi = <T = any>(
	url: URL | RequestInfo,
	options: FetchOption = {},
	typeResponse: 'json' | 'text' = 'json',
): Promise<T> => {
	const params: RequestInit = {
		...options,
		method: options.method ?? 'GET',
		headers: options.headers ?? {},
		body: options.body ? JSON.stringify(options.body) : null,
		credentials: options.credentials ?? 'same-origin',
	};
	return fetch(url, params).then<T>(res => {
		if (res.status >= 400) throw res;
		return typeResponse === 'json' ? res.json() : res.text();
	});
};

export const fetchPublicApi = (
	endpointResource: string,
	options: FetchOption = {},
): Promise<any> =>
	fetchApi(
		`${PUBLIC_API_URI || 'http://localhost:4000'}/api${endpointResource}`,
		{
			...options,
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		},
	);
