import React from 'react';
import cx from 'classnames';
import {getTranslate} from '../../../i18n';
import AddressCardIcon from '../../../public/static/theme-capcar/address-card-duotone.svg';
import PercentIcon from '../../../public/static/theme-capcar/badge-percent-duotone.svg';
import BagsShoppingIcon from '../../../public/static/theme-capcar/bags-shopping-duotone.svg';
import BellsIcon from '../../../public/static/theme-capcar/bells-duotone.svg';
import EuroIcon from '../../../public/static/theme-capcar/euro-duotone.svg';
import DocumentsIcon from '../../../public/static/theme-capcar/more-duotone.svg';
import UserIcon from '../../../public/static/theme-capcar/user-duotone.svg';
import {Customer} from '../../../types/generated-gateway-api-types';

type TranslateType = (
	arg0: string,
	arg1?: Record<string, any> | null | undefined,
) => string;

export type MenuItemPropsType = {
	label: React.ReactNode;
	description?: string;
	href: string;
	as?: string;
};

type AlertConfTypeProvider = (
	myAlerts: {
		id: number;
	}[],
	translate: TranslateType,
) => MenuItemPropsType;

type CustomerConfTypeProvider = (
	customer: Customer,
	translate: TranslateType,
) => MenuItemPropsType;

function getProjectsHref(myProducts, myQuotations): string {
	if (myProducts?.length === 1 && myQuotations?.length === 0) {
		return `/espace-client/mes-projets/${myProducts[0].id}`;
	}

	if (myProducts?.length === 0 && myQuotations?.length === 1) {
		return `/espace-client/mes-projets/estimations/${myQuotations[0].id}`;
	}

	return '/espace-client/mes-projets';
}

const formatLabel = (labelName, itemNumber) => (
	<div className="relative">
		<span className="pr-6">{labelName}</span>
		{typeof itemNumber !== 'undefined' && (
			<span
				className={cx('absolute right-0', {
					'text-blue-275': itemNumber > 0,
					'text-gray-100': itemNumber === 0,
					'right-0': itemNumber < 10,
					'-right-3': itemNumber >= 10 && itemNumber < 100,
					'-right-5': itemNumber >= 100,
				})}
			>
				({itemNumber})
			</span>
		)}
	</div>
);

export const getProjectsConf: CustomerConfTypeProvider = (
	customer,
	translate,
) => {
	return {
		label: formatLabel(
			translate('myProjects'),
			typeof customer?.myProducts !== 'undefined'
				? customer?.myProducts?.length + customer?.myQuotations?.length
				: undefined,
		),
		href: getProjectsHref(customer?.myProducts, customer?.myQuotations),
	};
};

export const getMyBargainingConf: CustomerConfTypeProvider = (
	customer,
	translate,
) => ({
	label: formatLabel(
		translate('myBargainings'),
		customer?.myBargainings?.length,
	),
	description: translate(
		customer?.myBargainings?.length === 1
			? 'bargainingDescription'
			: 'bargainingsDescription',
	),
	href: '/espace-client/mes-offres',
});

export const getMyBookingConf: CustomerConfTypeProvider = (
	customer,
	translate,
) => ({
	label: formatLabel(
		translate('myBookingsStatus'),
		customer?.myBookings?.length,
	),
	href:
		customer?.myBookings?.length === 1
			? `/espace-client/suivi-de-commande/${customer?.myBookings[0].id}`
			: '/espace-client/suivi-de-commande',
});

export const getMyAlertConf: AlertConfTypeProvider = (myAlerts, translate) => ({
	label: formatLabel(translate('myAlerts'), myAlerts?.length),
	href: '/espace-client/mes-alertes',
});

type LinkConf = {
	label: React.ReactNode;
	description: string;
	href: string;
	as?: string;
	hide?: boolean;
	icon: React.ReactNode;
};

export type LinksConfType = {
	bookings: LinkConf;
	projects: LinkConf;
	alerts: LinkConf;
	bargainings: LinkConf;
	profile: LinkConf;
	documents: LinkConf;
	invoices: LinkConf;
};

type BuildLinksConf = (data: Record<string, any>) => LinksConfType;

export const buildLinksConf: BuildLinksConf = data => ({
	bookings: {
		label: (
			<>
				{getTranslate('fr')('myBookingsStatus')}{' '}
				<span className="text-gray-100" />
			</>
		),
		description: getTranslate('fr')('bookingDescription'),
		href: '/espace-client/suivi-de-commande',
		icon: <BagsShoppingIcon />,
		...(data ? getMyBookingConf(data.profile, getTranslate('fr')) : null),
	},
	projects: {
		label: (
			<>
				{getTranslate('fr')('myProjects')}{' '}
				<span className="text-gray-100" />
			</>
		),
		description: getTranslate('fr')('productSaleDescription'),
		href: '/espace-client/mes-projets',
		icon: <AddressCardIcon />,
		...(data ? getProjectsConf(data.profile, getTranslate('fr')) : null),
	},
	alerts: {
		label: (
			<>
				{getTranslate('fr')('myAlerts')}{' '}
				<span className="text-gray-100" />
			</>
		),
		description: getTranslate('fr')('alertsDescription'),
		href: '/espace-client/mes-alertes',
		icon: <BellsIcon />,
		...(data ? getMyAlertConf(data.alerts, getTranslate('fr')) : null),
	},
	bargainings: {
		label: (
			<>
				{getTranslate('fr')('myBargainings')}{' '}
				<span className="text-gray-100" />
			</>
		),
		description: getTranslate('fr')('bargainingsDescription'),
		href: '/espace-client/mes-offres',
		icon: <PercentIcon />,
		...(data
			? getMyBargainingConf(data.profile, getTranslate('fr'))
			: null),
	},
	profile: {
		label: getTranslate('fr')('myProfile'),
		description: getTranslate('fr')('myProfileDescription'),
		href: '/espace-client/mon-profil',
		icon: <UserIcon />,
	},
	documents: {
		label: getTranslate('fr')('myDocuments'),
		description: getTranslate('fr')('myDocumentsDescription'),
		href: '/espace-client/mes-documents',
		icon: <DocumentsIcon />,
	},
	invoices: {
		label: getTranslate('fr')('invoicesAndCertificates.title'),
		description: getTranslate('fr')('invoicesAndCertificates.description'),
		href: '/espace-client/mes-factures',
		icon: <EuroIcon />,
	},
});
