import nextCookie from 'next-cookies';
import {useQuery} from '@apollo/client';
import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useMemo,
} from 'react';
import {AuthContext} from '../../../contexts/auth';
import {I18nContext} from '../../../i18n';
import {BUYER_INFOS_QUERY} from '../../../queries/booking';
import type {PageType, AppType} from '../../../types/PageType';
import {
	getMyAlertConf,
	getMyBargainingConf,
	getMyBookingConf,
	getProjectsConf,
	buildLinksConf,
	MenuItemPropsType,
	LinksConfType,
} from './utils';

type AccountMenuContextType = {
	myProjectsConf: MenuItemPropsType;
	setMyProjectsConf: (MenuItemPropsType) => void;
	myBookingConf: MenuItemPropsType;
	setMyBookingConf: (MenuItemPropsType) => void;
	myAlertConf: MenuItemPropsType;
	setMyAlertConf: (MenuItemPropsType) => void;
	myBargainingConf: MenuItemPropsType;
	setMyBargainingConf: (MenuItemPropsType) => void;
	linksConf: LinksConfType;
	setLinksConf: (LinksConfType) => void;
};

export const AccountMenuContext = createContext<AccountMenuContextType>({
	myProjectsConf: undefined,
	setMyProjectsConf: () => undefined,
	myBookingConf: undefined,
	setMyBookingConf: () => undefined,
	myAlertConf: undefined,
	setMyAlertConf: () => undefined,
	myBargainingConf: undefined,
	setMyBargainingConf: () => undefined,
	linksConf: undefined,
	setLinksConf: () => undefined,
});

export const useRefreshAccount = () => {
	const {translate} = useContext(I18nContext);
	const {email} = useContext(AuthContext);

	const {data} = useQuery(BUYER_INFOS_QUERY, {
		variables: {
			email,
			deleted: false,
		},
		fetchPolicy: 'cache-first',
		ssr: false,
		skip: !email,
	});

	const {
		setMyProjectsConf,
		setMyBookingConf,
		setMyAlertConf,
		setMyBargainingConf,
		setLinksConf,
	} = useContext(AccountMenuContext);

	useEffect(() => {
		if (data?.profile) {
			setMyProjectsConf(getProjectsConf(data?.profile, translate));
			setMyBookingConf(getMyBookingConf(data?.profile, translate));
			setMyAlertConf(getMyAlertConf(data?.alerts, translate));
			setMyBargainingConf(getMyBargainingConf(data?.profile, translate));
			setLinksConf(buildLinksConf(data));
		}
	}, [data]);
};

export const withAccountMenu = (Component: PageType | AppType) => {
	const Wrapper = ({awc, ...props}: any) => {
		const {translate} = useContext(I18nContext);
		const [myProjectsConf, setMyProjectsConf] = useState(
			getProjectsConf(undefined, translate),
		);
		const [myBookingConf, setMyBookingConf] = useState(
			getMyBookingConf(undefined, translate),
		);
		const [myAlertConf, setMyAlertConf] = useState(
			getMyAlertConf(undefined, translate),
		);
		const [myBargainingConf, setMyBargainingConf] = useState(
			getMyBargainingConf(undefined, translate),
		);

		const [linksConf, setLinksConf] = useState(buildLinksConf(undefined));

		const providerValue = useMemo(
			() => ({
				myProjectsConf,
				setMyProjectsConf,
				myBookingConf,
				setMyBookingConf,
				myAlertConf,
				setMyAlertConf,
				myBargainingConf,
				setMyBargainingConf,
				linksConf,
				setLinksConf,
			}),
			[
				myProjectsConf,
				setMyProjectsConf,
				myBookingConf,
				setMyBookingConf,
				myAlertConf,
				setMyAlertConf,
				myBargainingConf,
				setMyBargainingConf,
				linksConf,
				setLinksConf,
			],
		);

		return (
			<AccountMenuContext.Provider value={providerValue}>
				<Component {...props} />
			</AccountMenuContext.Provider>
		);
	};

	Wrapper.getInitialProps = async ctx => {
		const componentProps = Component.getInitialProps
			? await Component.getInitialProps(ctx)
			: {};
		const {awc} = nextCookie(ctx.ctx ? ctx.ctx : ctx);
		return {...componentProps, awc};
	};

	return Wrapper;
};
