import config from '../config';

const {CDN_HOST} = config;
export const CAPCAR_PHONE_NUMBER = '01\u00a084\u00a080\u00a000\u00a015';
export const CAPCAR_PHONE_NUMBER_HREF = 'tel:+33-1-84-80-00-15';
export const TRANSACTION_QUEUE_PHONE_NUMBER =
	'09\u00a080\u00a080\u00a002\u00a004';
export const CAPCAR_EMAIL_CONTACT = 'contact@capcar.fr';
export const CAPCAR_EMAIL_CONTACT_HREF = 'mailto:contact@capcar.fr';
export const BLOG_URL = '/blog/';
export const CRITAIR_DETAIL_LINK =
	'/blog/mieux-comprendre-la-vignette-critair/';
export const GDPR_LINK =
	'https://www.cnil.fr/fr/reglement-europeen-protection-donnees';
export const GTU_LINK = '/legal/conditions-generales-utilisation';
export const TERMS_OF_SALES_LINK = '/legal/conditions-generales-vente';
export const BACKGROUND_URL = '/static/hero-img.jpg';
export const PRODUCT_URL = '/voiture-occasion';
export const PALC_GOUV_URL =
	'https://www.primealaconversion.gouv.fr/dboneco/accueil/';
export const SITE_NAME = 'CapCar';
export const AVIS_VERIFIES_LINK = 'https://www.avis-verifies.com';
export const AVIS_VERIFIES_CL_LINK = 'https://cl.avis-verifies.com';
export const PAYZEN_ENDPOINT = 'https://api.payzen.eu';
export const PAYZEN_CRYPTON_CLIENT =
	'https://api.lyra.com/static/js/krypton-client/V4.0/ext/';
export const PAYZEN_API_CREATE_PAYMENT_URL =
	'https://api.payzen.eu/api-payment/V4/Charge/CreatePayment';
export const GOOGLE_RATING_URL =
	'https://www.google.com/search?q=capcar&rlz=1C5CHFA_enFR907FR908&oq=capcar+&aqs=chrome..69i57j69i60l6j69i65.2872j0j1&sourceid=chrome&ie=UTF-8#lrd=0x47e66ffd82105be9:0x390e677f20d404b1,3,,,';
export const MONTHS = [
	'january',
	'february',
	'march',
	'april',
	'may',
	'june',
	'july',
	'august',
	'september',
	'october',
	'november',
	'december',
];
export const TABLET_MIN_WIDTH = `(min-width: 1024px)`;
export const MD_MIN_WIDTH = `(min-width: 768px)`;
export const VVV_URL =
	'https://www.vevovo.fr/valeur/10-17/?MID=FR_COP_0_111_2_0_0_0';
export const AUTOBIZ_URL =
	'https://vendre.autobiz.fr/Reprise_LP2?utm_source=affiliation_kyp&utm_medium=cpl&utm_campaign=[referral]_ vendre_1h&eaf-publisher=affiliation_kyp&eaf-name=affiliation_kyp-[referral]_vendre_1h&eaf-creativetype=banner';
const pressLink = `/presse`;
export const advisorImagePath = `https://res.cloudinary.com/lghaauto/c_fill,w_300,h_300/email-assets/man.png`;
export const pressLinksConfig = [
	{
		src: `${CDN_HOST}/static/theme-capcar/Le_Monde.svg`,
		hoverSrc: `${CDN_HOST}/static/theme-capcar/Le_Monde_hover.svg`,
		alt: 'le monde',
		href: pressLink,
		width: '110.33',
		height: '24',
	},
	{
		src: `${CDN_HOST}/static/theme-capcar/france-2-invert.svg`,
		hoverSrc: `${CDN_HOST}/static/theme-capcar/france-2-hover.svg`,
		alt: 'france 2',
		href: pressLink,
		width: '28.37',
		height: '24',
	},
	{
		src: `${CDN_HOST}/static/theme-capcar/M6_logo.svg`,
		hoverSrc: `${CDN_HOST}/static/theme-capcar/M6_logo_hover.svg`,
		alt: 'M6',
		href: pressLink,
		width: '31.71',
		height: '24',
	},
	{
		src: `${CDN_HOST}/static/theme-capcar/tf1_logo.svg`,
		hoverSrc: `${CDN_HOST}/static/theme-capcar/tf1_logo_hover.svg`,
		alt: 'tf1',
		href: pressLink,
		width: '65.45',
		height: '24',
	},
	{
		src: `${CDN_HOST}/static/theme-capcar/rmc.svg`,
		hoverSrc: `${CDN_HOST}/static/theme-capcar/rmc_hover.svg`,
		alt: 'rmc',
		href: pressLink,
		width: '57.12',
		height: '24',
	},
	{
		src: `${CDN_HOST}/static/theme-capcar/capital.svg`,
		hoverSrc: `${CDN_HOST}/static/theme-capcar/capital_hover.svg`,
		alt: 'capital',
		href: pressLink,
		width: '80.11',
		height: '24',
	},
];
export const extendedPressLinksConfig = [
	...pressLinksConfig,
	{
		src: `${CDN_HOST}/static/theme-capcar/le_point.svg`,
		hoverSrc: `${CDN_HOST}/static/theme-capcar/le_point.svg`,
		alt: 'le point',
		href: pressLink,
		width: '100.95',
		height: '24',
	},
	{
		src: `${CDN_HOST}/static/theme-capcar/la_tribune.svg`,
		hoverSrc: `${CDN_HOST}/static/theme-capcar/la_tribune.svg`,
		alt: 'la tribune',
		href: pressLink,
		width: '52.55',
		height: '24',
	},
	{
		src: `${CDN_HOST}/static/theme-capcar/bfm.svg`,
		hoverSrc: `${CDN_HOST}/static/theme-capcar/bfm.svg`,
		alt: 'bfm',
		href: pressLink,
		width: '24',
		height: '24',
	},
	{
		src: `${CDN_HOST}/static/theme-capcar/lci.svg`,
		hoverSrc: `${CDN_HOST}/static/theme-capcar/lci.svg`,
		alt: 'lci',
		href: pressLink,
		width: '61.99',
		height: '24',
	},
];
export const UTM_CAMPAIGN_ALLIANZ = 'allianz';
export const SHOW_ADVICE_VIEW_THRESHOLDS = 25;

export const MAX_FILE_SIZE_PAPER_UPLOAD = 10_000_000; // 10mo
export const MIN_FILE_SIZE_PAPER_UPLOAD = 32_000; // 32ko
export const MIN_PDF_FILE_SIZE_PAPER_UPLOAD = 1_000; // 1ko
