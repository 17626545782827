import {gql} from '@apollo/client';

export const customerFragment = gql`
	fragment CustomerFragment on Customer {
		email
		firstName
		approachable
		lastName
		addressStreet
		addressZipCode
		addressCity
		phone
		birthday
		nationality
		countryOfResidence
		deleted
		warrantyDiscountEligible
	}
`;
export const CREATE_CUSTOMER = gql`
	mutation createCustomer($customer: CustomerCreationInput!) {
		createCustomer(customer: $customer) {
			customerId
			firstName
			lastName
			token
			email
		}
	}
`;
export const PROFILE_QUERY = gql`
	query profile {
		profile {
			id
			...CustomerFragment
		}
		${customerFragment}
	}
`;
export const LIGHT_PROFILE_QUERY = gql`
	query profile {
		profile {
			id
			email
			phone
			acceptPartner
			acceptCgu
			approachable
		}
	}
`;

export const UPDATE_CUSTOMER_MUTATION = gql`
	mutation($customer: CustomerInput!) {
		updateCustomer(customer: $customer) {
			...CustomerFragment
		}
		${customerFragment}
	}
`;
export const UPDATE_CUSTOMER_PASSWORD_MUTATION = gql`
	mutation ($customer: CustomerInput!) {
		updateCustomer(customer: $customer) {
			id
		}
	}
`;

export const CUSTOMER_TRACKING_MUTATION = gql`
	mutation ($trackingEvent: CustomerTrackingInput!) {
		customerTracking(trackingEvent: $trackingEvent) {
			eventId
		}
	}
`;
