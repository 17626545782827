import getConfig from 'next/config';

const config = getConfig()?.publicRuntimeConfig || {
	ENV_NAME: '',
	CDN_HOST: '',
	SENTRY_DSN: '',
};
export function hasFeature(feature: string): boolean {
	return config[`${feature}_FEATURE_ENABLED`] === 'true';
}
export default config;
