import React, {createContext, useEffect, useMemo} from 'react';
import nextCookie from 'next-cookies';
import Router from 'next/router';
import type {AppType, PageType} from '../../types/PageType';
import type {AuthContextType} from './types';
import {getAuthCookies} from './utils';
import AuthGuard from './guard';

export * from './utils';
export const AuthContext = createContext<AuthContextType>({loggedIn: false});

if (process.env.NODE_ENV === 'development') {
	AuthContext.displayName = 'AuthContext';
}

export const withAuthContext = (Component: PageType | AppType) => {
	const Wrapper = ({
		customerId,
		token,
		loggedIn,
		firstName,
		lastName,
		email,
		phone,
		...props
	}: any) => {
		const syncLogout = event => {
			if (event.key === 'logout') {
				// eslint-disable-next-line no-console
				console.log('logged out from storage!');
				Router.push('/');
			}
		};

		const providerValue = useMemo(
			() => ({
				customerId,
				token,
				loggedIn,
				firstName,
				lastName,
				email,
				phone,
			}),
			[customerId, token, loggedIn, firstName, lastName, email, phone],
		);

		useEffect(() => {
			window.addEventListener('storage', syncLogout);
			return () => {
				window.removeEventListener('storage', syncLogout);
			};
		}, []);
		return (
			<AuthContext.Provider value={providerValue}>
				<Component {...props} />
			</AuthContext.Provider>
		);
	};

	Wrapper.getInitialProps = async ctx => {
		const componentProps = Component.getInitialProps
			? await Component.getInitialProps(ctx)
			: {};
		const {customerId, token, firstName, lastName, email, phone} =
			getAuthCookies(nextCookie(ctx.ctx ? ctx.ctx : ctx));
		return {
			...componentProps,
			customerId,
			token,
			loggedIn: !!token,
			firstName,
			lastName,
			email,
			phone,
		};
	};

	return Wrapper;
};

const removeUrlParams = (url: string): string => url?.split('?')[0] ?? '';

export const withAuthOnly = (Component: PageType) => {
	const Wrapper = ({...props}: any) => (
		<>
			<Component {...props} />
			<AuthGuard />
		</>
	);

	Wrapper.getInitialProps = async ctx => {
		const {token} = getAuthCookies(nextCookie(ctx));

		if (!token) {
			if (typeof window === 'undefined') {
				ctx.res.writeHead(302, {
					Location: `/espace-client/authentification?path=${
						ctx.pathname
					}&as=${removeUrlParams(ctx.asPath)}`,
				});
				ctx.res.end();
			} else {
				await Router.push(
					`/espace-client/authentification?path=${
						ctx.pathname
					}&as=${removeUrlParams(ctx.asPath)}`,
				);
			}
		}

		return Component.getInitialProps ? Component.getInitialProps(ctx) : {};
	};

	return Wrapper;
};
