import {gql} from '@apollo/client';
import {productFragment} from './productFragment';

export const BUYER_INFOS_QUERY = gql`
	query myBookingInfos($email: Email!, $deleted: Boolean) {
		profile {
			id
			myProducts {
				...ProductFragment
			}
			myBookings {
				id
			}
			myBargainings {
				id
				disabled
				product {
					internalStatus
				}
			}
			myQuotations {
				id
			}
		}
		alerts(email: $email, deleted: $deleted) {
			id
		}
	}
	${productFragment}
`;
export const MY_BOOKINGS_QUERY = gql`
	query myBookingInfos {
		profile {
			id
			myBookings {
				...ProductFragment
			}
			myOldBookings {
				id
				price
				internalStatus
				thumb
				detail {
					id
					car {
						brand
						version
					}
				}
				order {
					id
					status
				}
			}
		}
	}
	${productFragment}
`;
export const BOOKING_INFO_QUERY = gql`
	query myBookingInfo($productId: String!) {
		profile {
			id
			myOldBookings {
				...ProductFragment
			}
			bankDetails(productId: $productId) {
				bic
				iban
				owner
			}
		}
		myGrayCard(productId: $productId) {
			id
		}
		myWarranty(productId: $productId) {
			id
			type
			duration
			provider
			amount
			paymentFrequency
		}
		bookingInfos(productId: $productId) {
			product {
				id
				price
				internalStatus
				thumb
				detail {
					id
					entryIntoServiceDate
					warrantyExpirationDate
					mileage
					critAir
					car {
						brand
						version
						carPackage
						energy
						gearbox
						motorization
					}
				}
				fundsPayins {
					id
					executionDate
					wireReference
					currency
					debitedFunds
				}
				agent {
					id
					pic
					phone
					firstName
					lastName
				}
			}
			booking {
				status
				booked
				date
			}
			paymentInfos {
				creditCard {
					amount
				}
				wallet {
					id
					amount
				}
				check {
					amount
					fees
					order
					number
				}
			}
			oldPaymentInfos {
				received
				amount
				type
			}
			appointment {
				scheduled
				delivery
				address {
					streetName
					city
					zipCode
				}
				date
			}
		}
	}
	${productFragment}
`;
export const MY_PRODUCT_WARRANTIES_QUERY = gql`
	query myAvailableWarranties($productId: String!) {
		bookingInfos(productId: $productId) {
			product {
				id
				warranties {
					type
					duration
					amount
				}
			}
		}
		myWarranty(productId: $productId) {
			id
			type
			duration
			amount
		}
	}
`;
