import {useApolloClient, useLazyQuery} from '@apollo/client';
import {useEffect} from 'react';
import {PROFILE_QUERY} from '../../queries/customer';
import {logout} from './utils';

const AuthGuard: React.FC = () => {
	const apolloClient = useApolloClient();
	const [getProfile] = useLazyQuery(PROFILE_QUERY, {
		onCompleted(data) {
			if (data.profile.deleted) {
				logout(apolloClient);
			}
		},
	});

	useEffect(() => {
		const initialzed = sessionStorage.getItem('initialized');
		if (initialzed !== 'true') {
			getProfile();
			sessionStorage.setItem('initialized', 'true');
		}
	}, []);
	return null;
};

export default AuthGuard;
